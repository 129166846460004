import React, { useEffect, useState } from 'react';
import { Button, TableCell, TableRow, Chip, Avatar } from '@material-ui/core';
import { ProductPackageStatus } from '../../data/common';
import { map, orderBy, forEach } from 'lodash';
import { AvatarGroup } from '@material-ui/lab';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import BurstModeRoundedIcon from '@material-ui/icons/BurstModeRounded';
import ConfirmationNumberRoundedIcon from '@material-ui/icons/ConfirmationNumberRounded';
import GroupProductTableCell from './GroupProductTableCell';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';

const ProductPackageTableRow = (props) => {
  const {
    productPackage,
    toEditProductPackage,
    handleOpenImgModal,
    handleOpenProductsModal,
  } = props;
  const [avatars, setAvatars] = useState();
  const [groupProducts, setGroupProducts] = useState();

  useEffect(() => {
    if (
      productPackage.packageProductImageList &&
      productPackage.packageProductImageList.length > 0
    ) {
      const newImageList = orderBy(
        productPackage.packageProductImageList,
        (packageProductImage) => packageProductImage.isCover,
        'desc',
      );

      setAvatars(newImageList);
    } else {
      setAvatars(undefined);
    }

    if (productPackage) {
      let newGroupProducts = {};

      forEach(productPackage.packageProductList, (packageProduct) => {
        const groupKey = packageProduct.isGift
          ? 'gift_' + packageProduct.bonusProductInfo.id
          : '' + packageProduct.bonusProductInfo.id;

        if (!newGroupProducts[groupKey]) {
          newGroupProducts[groupKey] = {
            name: packageProduct.bonusProductInfo.name,
            total: 0,
            isGift: packageProduct.isGift,
          };
        }

        newGroupProducts[groupKey].total = newGroupProducts[groupKey].total + 1;
      });

      setGroupProducts(newGroupProducts);
    }
  }, [productPackage]);

  const handleEdit = () => {
    toEditProductPackage(productPackage.id);
  };

  return (
    <TableRow>
      <TableCell component="th">
        {!!avatars && (
          <AvatarGroup max={5}>
            {map(avatars, (avatar) => (
              <Avatar
                key={avatar.imageId}
                alt={avatar.isCover ? '封面' : '圖片'}
                src={avatar.imageUrl}
                variant={avatar.isCover ? 'rounded' : 'circle'}
              />
            ))}
          </AvatarGroup>
        )}
      </TableCell>
      <TableCell component="th">{productPackage.id}</TableCell>
      <TableCell>{productPackage.name}</TableCell>
      <GroupProductTableCell groupProducts={groupProducts} />
      <TableCell>
        <Chip
          variant="default"
          size="small"
          icon={
            productPackage.status === ProductPackageStatus.ONLINE ? (
              <VisibilityRoundedIcon />
            ) : (
              <VisibilityOffRoundedIcon />
            )
          }
          label={
            productPackage.status === ProductPackageStatus.ONLINE
              ? '上線'
              : '下線'
          }
          color={
            productPackage.status === ProductPackageStatus.ONLINE
              ? 'primary'
              : 'default'
          }
        />
      </TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<BurstModeRoundedIcon />}
          style={{ marginRight: '12px' }}
          onClick={() => {
            handleOpenImgModal(productPackage.id);
          }}>
          圖片設定
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<ConfirmationNumberRoundedIcon />}
          style={{ marginRight: '12px' }}
          onClick={() => {
            handleOpenProductsModal(productPackage.id);
          }}>
          內容設定
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<EditRoundedIcon />}
          onClick={handleEdit}>
          編輯
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ProductPackageTableRow;
