import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  Typography,
  Divider,
  Box,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { forEach, map } from 'lodash';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import TableEmpty from '../TableEmpty';
import { LIST_PAGE_COUNT } from '../../data/common';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TableLoading from '../TableLoading';
import SearchIcon from '@material-ui/icons/Search';
import { Pagination } from '@material-ui/lab';
import AddProductCard from './AddProductCard';
import GroupPackageProductCard from './GroupPackageProductCard';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  packageProducts: {
    display: 'flex',
    gap: '12px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  packageProduct: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'stretch',
    flex: '1 1 100%',
    backgroundColor: '#eee',
    padding: '12px',
    borderRadius: '6px',
  },
  packageProductImg: {
    alignContent: 'center',

    '& > img': {
      width: '70px',
      objectFit: 'cover',
      borderRadius: '12px',
    },
  },
  packageProductContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '6px',
  },
  packageProductBtns: {
    marginLeft: 'auto',
    alignContent: 'center',
  },
  pagination: {
    justifyContent: 'center',
    margin: '8px auto',
  },
  tags: {
    display: 'flex',
    gap: '12px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

const ProductPackageProductSettingModal = (props) => {
  const {
    isOpen,
    close,
    isLoading,
    getProducts,
    productPackage,
    addProduct,
    saveGroupPackageProductTotal,
  } = props;
  const classes = useStyles();
  const [pageStep, setPageStep] = useState('packageProducts');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({
    keyword: '',
  });
  const [products, setProducts] = useState();
  const [productTotal, setProductTotal] = useState();
  const [groupPackageProducts, setGroupPackageProducts] = useState();

  useEffect(() => {
    if (pageStep === 'addProducts') {
      handleGetProducts();
    }
  }, [pageStep, page]);

  useEffect(() => {
    if (productPackage && productPackage.packageProductList.length > 0) {
      let newGroupProducts = {};

      forEach(productPackage.packageProductList, (packageProduct) => {
        const groupKey = packageProduct.isGift
          ? 'gift_' +
            packageProduct.packageExpriyDateDay +
            '_' +
            packageProduct.bonusProductInfo.id
          : '' + packageProduct.bonusProductInfo.id;

        if (!newGroupProducts[groupKey]) {
          newGroupProducts[groupKey] = {
            name: packageProduct.bonusProductInfo.name,
            productId: packageProduct.bonusProductInfo.id,
            expriyDay: packageProduct.packageExpriyDateDay,
            bonusProductInfo: packageProduct.bonusProductInfo,
            total: 0,
            isGift: packageProduct.isGift,
            userPackageProductIds: [],
          };
        }

        newGroupProducts[groupKey].total = newGroupProducts[groupKey].total + 1;
        newGroupProducts[groupKey].userPackageProductIds.push(
          packageProduct.id,
        );
      });

      setGroupPackageProducts(newGroupProducts);
    }

    return () => [setGroupPackageProducts(undefined)];
  }, [productPackage]);

  const handleGetProducts = () => {
    getProducts(page, search).then((rs) => {
      if (rs) {
        setProducts(rs.productList);
        setProductTotal(rs.totalCount);
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setPageStep('packageProducts');
      setPage(1);
      close();
    }
  };

  const handleAddProducts = () => {
    setPageStep('addProducts');
  };

  const handleBackPackageProducts = () => {
    setPageStep('packageProducts');
  };

  const handleAddProduct = (productId, isGift, count, expriyDay) => {
    addProduct(productPackage.id, productId, isGift, count, expriyDay);
  };

  if (!productPackage) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
      onClose={handleClose}>
      <DialogTitle>{productPackage.name} 套券內容</DialogTitle>

      {pageStep === 'packageProducts' && (
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center">
            <Grid item xs>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                目前套券內容
              </Typography>
            </Grid>

            <Grid item xs style={{ marignLeft: 'auto' }}>
              <div style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  disabled={isLoading}
                  size="small"
                  onClick={handleAddProducts}>
                  加入商品
                </Button>
              </div>
            </Grid>
          </Grid>

          <Divider style={{ margin: '4px 0' }} />

          {productPackage.packageProductList.length <= 0 && (
            <TableEmpty message="尚無設定套券內容" />
          )}

          {groupPackageProducts && (
            <div className={classes.packageProducts}>
              {map(groupPackageProducts, (groupPackageProduct) => (
                <GroupPackageProductCard
                  key={groupPackageProduct.productId}
                  groupPackageProduct={groupPackageProduct}
                  isLoading={isLoading}
                  packageProductId={productPackage.id}
                  saveGroupPackageProductTotal={saveGroupPackageProductTotal}
                />
              ))}
            </div>
          )}
        </DialogContent>
      )}

      {pageStep === 'addProducts' && (
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center">
            <Grid item xs>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                加入新商品
              </Typography>
            </Grid>

            <Grid item xs style={{ marignLeft: 'auto' }}>
              <div style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<ArrowBackIcon />}
                  disabled={isLoading}
                  size="small"
                  onClick={handleBackPackageProducts}>
                  返回
                </Button>
              </div>
            </Grid>
          </Grid>

          <Divider style={{ margin: '4px 0' }} />

          <Box mt={2} mb={2}>
            <TextField
              label="名稱"
              type="search"
              name="keyword"
              variant="outlined"
              size="small"
              disabled={isLoading}
              value={search.keyword}
              onChange={(e) => {
                setSearch({
                  ...search,
                  keyword: e.target.value,
                });
              }}
              style={{ marginRight: '12px' }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  if (page !== 1) {
                    setPage(1);
                  } else {
                    handleGetProducts();
                  }
                }
              }}
            />

            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              disabled={isLoading}
              onClick={() => {
                if (page !== 1) {
                  setPage(1);
                } else {
                  handleGetProducts();
                }
              }}>
              搜尋
            </Button>
          </Box>

          {isLoading && <TableLoading />}

          {!isLoading && (!products || products.length <= 0) && (
            <div>查無商品</div>
          )}

          {!isLoading && products && products.length > 0 && (
            <div>
              {map(products, (product) => {
                return (
                  <AddProductCard
                    key={product.id}
                    product={product}
                    addProduct={handleAddProduct}
                    isLoading={isLoading}
                  />
                );
              })}

              <Box display="flex">
                <Pagination
                  className={classes.pagination}
                  count={Math.ceil(productTotal / LIST_PAGE_COUNT)}
                  color="primary"
                  disabled={isLoading}
                  page={page}
                  onChange={(e, page) => {
                    setPage(page);
                  }}
                />
              </Box>
            </div>
          )}
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductPackageProductSettingModal;
