import React, { useEffect, useState } from 'react';
import { Button, Chip, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BonusProductTypeText } from '../../data/common';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles({
  packageProduct: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'stretch',
    flex: '1 1 100%',
    backgroundColor: '#eee',
    padding: '12px',
    borderRadius: '6px',
  },
  packageProductImg: {
    alignContent: 'center',

    '& > img': {
      width: '70px',
      objectFit: 'cover',
      borderRadius: '12px',
    },
  },
  packageProductContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '6px',
  },
  packageProductBtns: {
    marginLeft: 'auto',
    alignContent: 'center',
  },
  tags: {
    display: 'flex',
    gap: '12px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  packageProductTotal: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    gap: '12px',
  },
});

const GroupPackageProductCard = (props) => {
  const {
    packageProductId,
    groupPackageProduct,
    isLoading,
    saveGroupPackageProductTotal,
  } = props;
  const classes = useStyles();
  const [packageProductTotal, setPackageProductTotal] = useState(() => {
    return groupPackageProduct.total;
  });

  useEffect(() => {
    if (groupPackageProduct) {
      setPackageProductTotal(groupPackageProduct.total);
    }
  }, [groupPackageProduct]);

  const handleSave = () => {
    saveGroupPackageProductTotal(
      packageProductId,
      groupPackageProduct,
      packageProductTotal,
    );
  };

  return (
    <div className={classes.packageProduct}>
      <div className={classes.packageProductImg}>
        {groupPackageProduct.bonusProductInfo.mainImage && (
          <img src={groupPackageProduct.bonusProductInfo.mainImage.imageName} />
        )}
      </div>

      <div className={classes.packageProductContent}>
        <div>{groupPackageProduct.bonusProductInfo.name}</div>

        <div className={classes.tags}>
          <Chip
            variant="outlined"
            size="small"
            color="primary"
            label={
              BonusProductTypeText[groupPackageProduct.bonusProductInfo.type]
            }
          />

          {groupPackageProduct.isGift && (
            <>
              <Chip
                variant="outlined"
                size="small"
                color="secondary"
                label="贈品"
                icon={<CardGiftcardRoundedIcon />}
              />

              <Chip
                variant="outlined"
                size="small"
                color="default"
                label={`使用期限 ${groupPackageProduct.expriyDay} 天`}
                icon={<CardGiftcardRoundedIcon />}
              />
            </>
          )}
        </div>
      </div>

      <div className={classes.packageProductBtns}>
        <div className={classes.packageProductTotal}>
          <span>數量</span>
          {packageProductTotal === 0 ? '移除' : packageProductTotal}
        </div>
        <ButtonGroup>
          <Button
            size="small"
            disabled={packageProductTotal <= 0 || isLoading}
            onClick={() => {
              setPackageProductTotal(packageProductTotal - 1);
            }}>
            <RemoveIcon fontSize="small" />
          </Button>
          <Button
            size="small"
            disabled={isLoading}
            onClick={() => {
              setPackageProductTotal(packageProductTotal + 1);
            }}>
            <AddIcon fontSize="small" />
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={
              isLoading || packageProductTotal === groupPackageProduct.total
            }
            onClick={handleSave}>
            儲存
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default GroupPackageProductCard;
