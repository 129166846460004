import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { BonusProductTypeText } from '../../data/common';
import Swal from 'sweetalert2';
import { parseInt, trim } from 'lodash';

const useStyles = makeStyles({
  packageProduct: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'stretch',
    flex: '1 1 100%',
    backgroundColor: '#f8f8f8',
    padding: '12px',
    borderRadius: '6px',
    margin: '4px 0',
  },
  packageProductImg: {
    alignContent: 'center',

    '& > img': {
      width: '70px',
      objectFit: 'cover',
      borderRadius: '12px',
    },
  },
  packageProductContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '6px',
  },
  packageProductBtns: {
    marginLeft: 'auto',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    gap: '6px',
  },
  tags: {
    display: 'flex',
    gap: '12px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

const AddProductCard = (props) => {
  const classes = useStyles();
  const { product, addProduct, isLoading } = props;
  const [count, setCount] = useState();
  const [isGift, setIsGift] = useState(false);
  const [expriyDay, setExpriyDay] = useState();

  const handleAddProduct = () => {
    if (isGift && (trim(expriyDay) === '' || parseInt(expriyDay, 10) < 0)) {
      Swal.fire({
        title: '請輸入使用期限',
        icon: 'warning',
      });

      return false;
    }

    addProduct(product.id, isGift, count, expriyDay);
  };

  return (
    <div className={classes.packageProduct}>
      <div className={classes.packageProductImg}>
        {product.mainImage && <img src={product.mainImage.imageName} />}
      </div>

      <div className={classes.packageProductContent}>
        <div>{product.name}</div>

        <div className={classes.tags}>
          <Chip
            variant="outlined"
            size="small"
            color="primary"
            label={BonusProductTypeText[product.type]}
          />
        </div>
      </div>

      <div className={classes.packageProductBtns}>
        <div>
          <FormControlLabel
            label="贈品"
            control={
              <Checkbox
                checked={isGift}
                onChange={(e) => {
                  setIsGift(e.target.checked);
                }}
                disabled={isLoading}
              />
            }
          />

          <TextField
            variant="outlined"
            size="small"
            value={expriyDay || ''}
            onChange={(e) => {
              const value = e.target.value;

              if (/^[0-9]*$/.test(value)) {
                setExpriyDay(value);
              }
            }}
            label="使用期限[天數]"
            disabled={isLoading || !isGift}
          />
        </div>

        <TextField
          variant="outlined"
          size="small"
          value={count || ''}
          onChange={(e) => {
            const value = e.target.value;

            if (/^[0-9]*$/.test(value)) {
              setCount(value);
            }
          }}
          label="數量"
          disabled={isLoading}
        />

        <Button
          variant="contained"
          size="small"
          color="primary"
          startIcon={<AddCircleOutlineRoundedIcon />}
          onClick={handleAddProduct}
          disabled={
            isLoading ||
            !count ||
            count <= 0 ||
            (isGift === true && (!expriyDay || parseInt(expriyDay, 10) < 0))
          }>
          新增
        </Button>
      </div>
    </div>
  );
};

export default AddProductCard;
